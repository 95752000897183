
.box{
    background-color: #E1F9F4;
    width: 100%;
    height: 100%;
    position: absolute;
    
}


#rectangle1{
    position: absolute;
    width: 25%;
    height: 50%;
    margin-left: 550px;
    margin-top: 100px;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding-top: 25px;
}
#sub-head1{
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: 500px;
    margin-left: 630px;

}
input{
    padding: 10px;
    width:80%;
    margin: 15px;
    border-radius: 5px;
}
#btn1{
    width: 86%;
    background-color: #4C57B6;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    color: #FFFFFF;
}
Link{
    color: #4C57B6;
}
h2{
    color: #4C57B6;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 28px;
}
#title{
    font-size: 12px;
    color: #000000;
}