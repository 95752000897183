@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 100%;
  font-family: "Source Sans Pro", sans-serif;
}
body {
  background: #afeee1;
  /* overflow:hidden; */
}
.home {
  display: flex;
  max-width: 100vw;
  height: 100vh;
}

/* SIDE NAVBAR START */
.side-nav {
  width: 18vw;
  background-color: #afeee1;
  display: flex;
  flex-direction: column;
}
.side-nav h1 {
  text-align: center;
  color: #1a1b1c;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 40px;
}
.side-nav p {
  margin: 1rem 0;
  margin-left: 46px;
  display: flex;
  align-items: center;
  color: #aaaaaa;
}
.side-nav p span {
  padding-right: 3rem;
  /* padding-right: 1rem; */
}
.blue {
  color: #2289ff;
}

/* SIDE NAVBAR END */

.display {
  width: 82vw;
  max-height: 99vh;
}
.upper-nav {
  width: 100%;
  height: 12%;
  background-color: #f5f5f5;
}
.account {
  width: 200px;
  align-items: center;
  cursor: pointer;
}
.account p{
  /* border: 1px solid rebeccapurple; */
  border-radius: 0 0 5px 5px;
  position:absolute;
  left: 83%;
    top: 7%;
    padding: 0.3em 3.9rem;
  background: #ffffff;
  color:#494d4c;
  font-weight: 700;
}
.account .account-name{
  padding: 0 0.5em 0.5em;
}
.logout-option{
  margin-top: 0.5em;
  width: 162px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  /* font-weight: 700; */
  cursor: pointer;
}
.logout-option{
  margin-top: 0.5eem;
  /* width: 100%; */
}
.dashboard {
  width: 100%;
  height: 150vh;
  background-color: #f5f5f5;
  margin-top: 0.2rem;
}

.upper-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bcbcbc;
  padding: 0 2.8rem;
}

.search-addProperty {
  display: flex;
  justify-content: space-between;
  padding: 1.8rem 2.8rem;
  color: #bcbcbc;
}
.search-bar {
  height: 2.8rem;
  width: 20rem;
  border-radius: 2rem 2rem;
  color: #bcbcbc;
  background-color: #ffffff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.13);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}
.search-input {
  padding-left: 2rem;
  height: 2.8rem;
  width: 15rem;
  border-radius: 2rem 2rem;
  border: none;
  color: #bcbcbc;
  outline: none;
}
.border-left-line {
  border-left: 1.5px solid #e6e6e6;
  font-size: 1.6rem;
  margin-left: 16px;
}
.search-icon {
  margin-left: 10px;
  align-self: auto;
  color: #6ab4f8;
}
.search-addProperty .add-btn {
  height: 2.8rem;
  border-radius: 2rem 2rem;
  background-color: #6ab4f8;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.13);
  width: 8.25rem;
  border: none;
  outline: none;
}

/* all property lists @@@@@ */
.property-lists table {
   display: flex;
  justify-content: space-between;
  margin: 0 0.6rem 0 3rem ;
  flex-direction: column;
}
.property-lists table tr td{
  text-align: center;
}

.property-lists{
  height: 31rem;
  overflow-y: scroll;
  width: 96%;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background: #afeee2;
  border-radius: 5px;
  
  
}
::-webkit-scrollbar-thumb:hover {
  background: #afeee1;
}
.property-lists tr{
  display: flex;
  height: 3rem;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 0.8rem 0.8rem;
  padding: 0 2rem;
  margin-bottom: 0.5rem;
}
.property-lists thead tr:first-child {
  font-family: Source Sans Pro;
  font-weight: 400;
  color: #284e91;
  font-size: 15px;
}
.no-data-msg td{
padding-left: 430px;
font-size: larger;
color: red;
font-weight: 700;
}
.gray-color {
  color: #c3c3c3;
}
.action{
  /* padding-left: 40px; */
  width: 100%;
  cursor: pointer;
}
.view-icon{
  margin-right: 10px;
}
.action-btn{
  width: 5rem;
  height: 2rem;
  background-color:#F5FAF5 ;
  border-radius: 0.5em;
  border: none;
  outline: none;
  color: #416899;
  cursor: pointer;
}