.heading {
    position: absolute;
    left: -3.38%;
    right: 43.88%;
    top: 16.57%;
    bottom: 80.56%;
    /* background-color: white; */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 31px;
    text-transform: uppercase;
    color: #7D7D7D;


}

.menubar {
    position: absolute;
    left: 24.69%;
    right: 5.62%;
    top: 22.31%;
    bottom: 71.2%;
    display: flex;
    flex-direction: row;
    gap: 5%;
    background: #FFFFFF;
    border-radius: 500px;
}

.menu {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    height: inherit;
    box-sizing: border-box;
    box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.15);
    border-radius: 500px;
    color: #AAAAAA;
}


.pageno {
    position: absolute;
    display: flex;
    margin-right: 15%;
    width: 3%;
    align-content: center;
    justify-content: center;
    border-radius: 100%;
    background: #E1F9F4;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;

    color: #A6A6A6;
}

.a {
    background: #6AB4F8;
    color: #FFFFFF;
}

.b {
    background: white;
    color: black;
}